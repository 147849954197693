<template>
  <div class="app_con">
    <app-list :remote="remote" :props="props" :btnFlag="btnFlag" :formProps="formProps" :searchQuery="searchQuery"
      :apiName="apiName" :rules="rules" ref="appList">
      <div class="search" slot="subSlot">
        <el-row class="fullWidth">
          <el-col :span="4">
            <el-button type="primary" icon="el-icon-plus" @click="publicfuc.jumpToPage('create', {}, $router)"
              size="small">新增</el-button>

            <el-dropdown>
              <el-button type="primary" size="small" class="m-l-10">
                批量操作<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="setGoodsPutAway(0)">批量上架</el-dropdown-item>
                <el-dropdown-item @click.native="setGoodsPutAway(1)">批量下架</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown></el-col>
          <el-col :span="20">
            <search-bar :props="searchProps" @searchChange="searchChange"></search-bar></el-col>
        </el-row>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    let self = this;
    return {
      selecTionArr: [],
      remote: api.common,
      apiName: "/goodsBasicSetting",
      searchVal: "",
      bntLoading: false,
      btnFlag: { addShow: false, delShow: false },
      searchQuery: { refreshNum: 0, storeId: '' },
      rules: {
        //传递给APPlist的表单验证
      },
      searchProps: [
        { type: "input", label: "商品名称", prop: "name" },
        {
          type: "select",
          label: "分类",
          prop: "classificationGoodsId",
          selectData: [],
        },
        { type: "select", label: "店铺", prop: "storeId", selectData: [] },
      ],
      props: [
        {
          label: "商品名称",
          prop: "name",
          align: "center",
        },

        {
          label: "主图",
          prop: "image",
          align: "center",
          type: "html",
          formatter: function (row) {
            if (row.image.indexOf(",") !== -1) {
              return (
                "<img src=api/" +
                row.image.split(",")[0] +
                " class='tablePic'/>"
              );
            } else {
              return "<img src=api/" + row.image + " class='tablePic'/>";
            }
          },
        },

        {
          label: "分类",
          prop: "classificationGoodsName",
          align: "center",
        },

        {
          label: "是否上架",
          prop: "isPutaway",
          align: "center",
          type: "html",
          formatter: function (row) {
            const map = [
              { color: "#409EFF", text: "在售" },
              { color: "#666", text: "下架" },
            ];
            return (
              "<span style='color:" +
              map[row.isPutaway].color +
              "'>" +
              map[row.isPutaway].text +
              "</span>"
            );
          },
        },
        {
          label: "销售价",
          prop: "salesPrice",
          align: "center",
        },
        {
          label: "库存",
          prop: "inventory",
          align: "center",
        },
        {
          label: "店铺",
          prop: "storeName",
          align: "center",
        },
        {
          label: "操作",
          prop: "qrcode",
          align: "center",
          width: 250,
          type: "operationLink",
          formatter: function (row) {
            const buttonList = [
              {
                text: "编辑",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-edit",
                type: "primary",

                handler: function () {
                  self.jumpToDetail(row.id);
                },
              },
              {
                text: "删除",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-delete",
                type: "danger",
                handlerType: "remove",
                handler: function () { },
              },
            ];

            // let putAwayText = row.isPutaway == 0 ? "下架" : "上架";

            // buttonList.push({
            //   text: putAwayText,
            //   size: "mini",
            //   data: row,
            //   show: true,
            //   icon: "el-icon-sell",
            //   type: "primary",
            //   handler: function () {
            //     self.setGoodsPutAway(row.id, row.isPutaway);
            //   },
            // });

            return buttonList;
          },
        },
      ],
      formProps: [
        {
          label: "店铺名",
          type: "input",
          prop: "storeName",
        },
        {
          label: "老板",
          type: "input",
          prop: "name",
        },
        {
          label: "店铺地址",
          type: "input",
          prop: "address",
        },
        {
          label: "联系电话",
          type: "input",
          prop: "phone",
        },
        {
          label: "店铺描述",
          type: "input",
          prop: "description",
        },

        {
          label: "logo",
          type: "upload",
          prop: "logo",
        },
      ],
    };
  },
  created() { },
  mounted() {
    this.getCategoryList()

    this.getStoreList()

  },
  methods: {
    searchChange(e) {
      this.searchQuery = e;
      this.searchQuery.timer = new Date().getTime();
    },

    async getCategoryList() {
      let loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      try {
        let res = await api.common.commonGet({
          apiName: "/classificationGoods",
        });
        this.searchProps[1].selectData = [];
        res.data.forEach((element) => {
          this.searchProps[1].selectData.push({
            label: element.name,
            value: element.id,
          });
        });
      } catch (error) {
        console.log(error);
      } finally {
        loading.close();
      }
    },
    jumpToDetail(id) {
      this.$router.push({ path: "/goods/create", query: { id: id } });
    },


    async getStoreList() {

      let res = await api.common.commonGet({ apiName: '/store', });

      if (res.code == 200) {
        this.searchProps[2].selectData = [];
        res.data.data.forEach((element) => {
          this.searchProps[2].selectData.push({
            label: element.storeName,
            value: element.id,
          });
        });
      }
    },

    async setGoodsPutAway(value) {
      if (!this.$refs.appList.selecTionArr.length) {
        this.$message.error("请至少选择一条数据！");
        return;
      }

      let loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });

      try {
        let ids = [];
        this.$refs.appList.selecTionArr.forEach((element) => {
          ids.push(element.id);
        });

        let res = await api.common.commonPost({
          apiName: "/goodsBasicSetting/isPutaway",
          ids: ids,
          isPutaway: value,
        });
        this.$message.success(res.message);
        this.searchQuery.refreshNum++;
      } catch (error) {
        console.log(error);
      } finally {
        loading.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.changeCon p {
  color: #999;
}

.changeCon b {
  margin: 0 10px;
  color: #66b1ff;
}

.search {
  display: flex;
}

.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
